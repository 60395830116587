<script>
import api from '@/command/api'
import DetailFormGroup from '../../../components/DetailFormGroup'

export default {
  name: 'logLogDetail',
  data() {
    return {
      ...api.command.getState(),
      detail: {}
    }
  },
  mounted() {
    const { id } = this.$route.query
    id &&
      api.command.getDetail.call(this, {
        url: `/log/${id}`
      })
  },
  methods: {
    getForm1() {
      return {
        title: '登录日志详情',
        type: 'cardForm',
        gutter: 15,
        data: [
          {
            name: '浏览器',
            type: 'text',
            cols: 8,
            key: 'browser'
          },
          {
            name: '浏览器版本',
            type: 'text',
            cols: 8,
            key: 'browserVersion'
          },
          {
            name: '类名',
            type: 'text',
            cols: 8,
            key: 'className'
          },
          {
            name: '客户端标示',
            type: 'text',
            cols: 8,
            key: 'clientId'
          },
          {
            name: '创建人',
            type: 'text',
            cols: 8,
            key: 'createBy'
          },
          {
            name: '创建时间',
            type: 'text',
            cols: 8,
            key: 'createTime'
          },
          {
            name: '引擎',
            type: 'text',
            cols: 8,
            key: 'engine'
          },
          {
            name: '引擎版本',
            type: 'text',
            cols: 8,
            key: 'engineVersion'
          },
          {
            name: '异常信息',
            type: 'text',
            cols: 8,
            key: 'exception'
          }
        ]
      }
    },
    getFoot() {
      const left = [
        {
          name: '返回',
          type: 'primary',
          onClick: () => {
            this.$router.push('/monitoring/logLog')
          }
        }
      ]

      return {
        left
      }
    }
  },
  render() {
    return <DetailFormGroup foot={this.getFoot()} form={this.detail} data={[this.getForm1()]} />
  }
}
</script>

<style scoped></style>
